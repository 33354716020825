import { Container as MuiContainer } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.div`
  margin: 148px 0;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 96px 0 32px;
  }
`
export const Content = styled(MuiContainer)``
export const Title = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 43px;
  letter-spacing: 0.01em;
  color: #031915;
  padding-bottom: 72px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 32px;
  }
`

export const Item = styled.div`
  background: #fff;
  box-shadow: 0 4px 26px rgba(0, 0, 0, 0.11);
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 32px 32px;
  max-width: 360px;

  svg {
    width: 48px;
    height: 48px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: unset;
    margin-bottom: 48px;
  }
`

export const Icon = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  color: #fff;
  background: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -50px;
  margin-bottom: 16px;
`
export const Label = styled.div`
  font-weight: 700;
  font-size: 21px;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.main};
  padding-bottom: 16px;
`
export const Text = styled.div`
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: #666;

  a {
    text-decoration: none;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.primary.main};
    text-transform: uppercase;
  }
`
