import { Box, Link, Container as MuiContainer, withTheme } from '@mui/material'
import styled from 'styled-components'

export const Container = styled(Box)`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled(MuiContainer)``
export const Main = styled(Box)`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  &:hover {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`
