import { Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
`

export const FloatingIcon = styled.span<{ number: number }>`
  position: absolute;

  font-size: 60px;

  @media (min-width: 480px) {
    display: none;
  }

  ${({ number }) => {
    if (number === 1) {
      return css`
        rotate: -10deg;
        bottom: -100px;
        right: -40px;

        img {
        }
      `
    }
    if (number === 2) {
      return css`
        rotate: -10deg;
        top: 20px;
        left: -50px;

        img {
          width: 100px;
        }
      `
    }
    if (number === 3) {
      return css`
        rotate: 10deg;
        top: 240px;
        right: -70px;
        transform: scale(1.6);
      `
    }
    if (number === 4) {
      return css`
        top: 170px;
        left: -70px;
      `
    }
    if (number === 5) {
      return css`
        top: 30px;
        right: -70px;
        z-index: 100;
      `
    }

    return null
  }}
`

export const Icon = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background: #fff;
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07), 0 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -100px;
  left: 50%;
  margin-left: -40px;
  svg {
    width: 64px;
    height: 64px;
  }
`
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const Title = styled(Typography)`
  font-size: 25px;
  line-height: 31px;
  color: #031915; //${({ theme }) => theme.palette.secondary.main};
  padding-bottom: 16px;
  font-weight: 600;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 17px;
    /* margin-top: 30px; */
  }
`
export const Amount = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  position: relative;
  margin-bottom: 16px;
  padding-bottom: 16px;

  &:after {
    content: '';
    width: 90px;
    height: 3px;
    background: ${({ theme }) => theme.palette.primary.main};
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -45px;
  }
`
export const TotalOfParticipants = styled.button`
  margin-bottom: 24px;
  color: #333;
  font-weight: 600;
  border: 0;
  background: none;
  font-size: 16px;
  display: inline-block;
  cursor: pointer;

  border: 3px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 20px;
  padding: 5px 15px;

  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`

export const Currency = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 25px;
  line-height: 1;
  padding-right: 8px;
  @media (max-width: 480px) {
    font-size: 20px;
  }
`
export const Value = styled(Typography)`
  line-height: 1;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  font-size: 55px;
  @media (max-width: 480px) {
    /* font-size: 42px; */
  }
`
export const DateElement = styled.div`
  font-weight: 500;
  font-size: 17px;
  color: #828282;
  padding-bottom: 16px;
  text-align: center;
`
export const Observation = styled(DateElement)`
  max-width: 420px;
  text-align: center;
  font-size: 14px;
  color: #999;
  font-weight: 600;
  b {
    color: #37bcac;
    /* font-size: 16px; */
  }

  @media (max-width: 480px) {
    font-size: 10px;
  }
`

export const Prizes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`
export const Prize = styled.div`
  font-weight: 600;
  font-size: 23px;
  color: #363636;
  margin: 8px 0;
  line-height: 1;

  @media (max-width: 480px) {
    font-size: 18px;
  }
`
export const StyledButton = styled(Button)`
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  background: ${({ theme }) => theme.palette.secondary.main};
  color: #fff;
  height: 68px;
  border-radius: 34px;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.main};
  }

  svg {
    margin-left: 16px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    padding: 0 16px;
    width: 100%;
    white-space: nowrap;
    height: 48px;

    svg {
      margin-left: 8px;
    }
  }
`

export const Note = styled.div`
  margin-top: 24px;
  font-size: 12px;
  color: #777;
  max-width: 280px;
  text-align: center;
`

export const NoteLink = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.main};
`
