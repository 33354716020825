import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  html,body{
    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin: 0;
    overflow-x: hidden;
  }
`

export const AffiliateContainer = styled.div`
  background: #f5f7fb;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`
