import { forwardRef, useEffect, useState } from 'react'

import { ArrowForward } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { addDays, format, getHours, parseISO } from 'date-fns'
import { collection, getDocs, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import styled from 'styled-components'

import logo from 'main/assets/logo-icon.png'
import { firestore } from 'main/firebase'

import { Actions, CancelButton, StyledButton } from '../RaffleBox/components/PaymentModal/styles'
import { Container, Content, Header, Logo, Subtitle, Texts, Title } from './styles'

const ucfirst = string => {
  const firstLetter = string[0].toUpperCase()
  const rest = string.slice(1).toLowerCase()
  return `${firstLetter}${rest}`
}

const collapseName = name => {
  let outputName = ''
  if (name) {
    const fullName = name.trim().toLowerCase()
    const explode = fullName.split(' ')
    const firstName = explode[0]
    const lastNameFirstCharacter = explode[explode.length - 1].charAt(0).toUpperCase()

    outputName = `${ucfirst(firstName)} ${lastNameFirstCharacter}`
  }

  return outputName
}

const StyledParticipant = styled.div`
  font-size: 20px;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`

const ParticipantsModal = forwardRef<any, any>(({ handleClose, handleParticipate }, ref) => {
  const [participantsList, setParticipantsList] = useState<any>([])

  const fetchRaffle = async () => {
    const raffleRef = query(
      collection(firestore, 'raffle'),
      where('status', '==', 'OPEN'),
      orderBy('createdAt', 'desc'),
      limit(1)
    )

    const currentRaffle = await getDocs(raffleRef)

    const subCollections = await query(collection(firestore, 'raffle', currentRaffle.docs[0].id, 'participants'))

    onSnapshot(subCollections, snapshot => {
      if (!snapshot.empty) {
        const participantsData = snapshot.docs.map(item => {
          const data = item.data()
          const outputData = {
            name: collapseName(data.additionalInfo.name),
            createdAt: format(new Date(parseISO(data.pixData.calendario.criacao)), 'dd/MM/yyyy - HH:mm')
          }

          return outputData
        })
        setParticipantsList(participantsData)
      }
    })
  }

  useEffect(() => {
    fetchRaffle()
  }, [])

  return (
    <Container ref={ref}>
      <Content>
        <Header>
          <Texts>
            <Title>Participantes do PIX do dia</Title>
            <Subtitle>
              Desejamos <b>boa sorte</b> à todos que estão na lista abaixo.
            </Subtitle>
          </Texts>
          <Logo>
            <img src={logo} alt='' />
          </Logo>
        </Header>
        <Grid container spacing={2}>
          {participantsList.map(item => {
            return (
              <>
                <Grid item xs={6}>
                  <StyledParticipant>{item.name}</StyledParticipant>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  <StyledParticipant>{item.createdAt} h</StyledParticipant>
                </Grid>
              </>
            )
          })}
          {participantsList.length === 0 && (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography sx={{ my: '30px' }}>Este sorteio ainda possui participantes. Seja o primeiro! 🚀</Typography>
            </Grid>
          )}
        </Grid>
        <Actions>
          <CancelButton onClick={handleClose} type='button' variant='outlined' color='primary'>
            Voltar
          </CancelButton>
          <StyledButton type='button' variant='contained' color='primary' onClick={handleParticipate}>
            Participar agora!
            <ArrowForward className='arrow' />
          </StyledButton>
        </Actions>
      </Content>
    </Container>
  )
})

export default ParticipantsModal
