import React from 'react'

import { Pix, RocketLaunch, StarRounded } from '@mui/icons-material'
import { Grid } from '@mui/material'

import { globalSettings } from 'globalSettingsReact'
import { currencyFormatter } from 'main/functions'

import { Container, Content, Item, Label, Text, Title, Icon } from './styles'

function HowItWorks() {
  return (
    <Container>
      <Content>
        <Title>Como funciona?</Title>
        <Grid container spacing={2}>
          <Grid item sm={4} xs={12}>
            <Item>
              <Icon>
                <RocketLaunch />
              </Icon>
              <Label>Participe do sorteio</Label>
              <Text>
                Para participar do sorteio é simples. Participe do sorteio realizando um Pix no valor de R${' '}
                {currencyFormatter(globalSettings.ticketPrice)} e concorra a uma premiação mínima de R${' '}
                {currencyFormatter(globalSettings.thresholdAmount)}
              </Text>
            </Item>
          </Grid>

          <Grid item sm={4} xs={12}>
            <Item>
              <Icon>
                <Pix />
              </Icon>
              <Label>Faça o pagamento</Label>
              <Text>
                O pagamento é feito através de PIX, realizado de forma rápida e prática. Após a confirmação do pagamento
                você estará participando do sorteio automaticamente.
              </Text>
            </Item>
          </Grid>

          <Grid item sm={4} xs={12}>
            <Item>
              <Icon>
                <StarRounded />
              </Icon>
              <Label>Aguarde o sorteio</Label>
              <Text>
                Todos os dias as 18:00 (horário de Brasília), informamos quem foi o ganhador no nosso canal do{' '}
                <a href='https://t.me/pixdodiabr' target='_blank' rel='noreferrer noopener'>
                  Telegram
                </a>{' '}
                e o prêmio é enviado automaticamente para conta do ganhador.
              </Text>
            </Item>
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}

export default HowItWorks
