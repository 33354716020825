import * as React from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import logo from 'main/assets/logo-black.png'
import { auth } from 'main/firebase'

import { Container, Content, Main, StyledLink } from './styles'

export default function SignIn() {
  const navigate = useNavigate()
  const schema = yup
    .object({
      email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
      password: yup.string().min(6, 'A senha precisa conter no mínimo 6 caractéres').required('Campo obrigatório')
    })
    .required()

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data: any) => {
    const { email, password } = data
    try {
      await signInWithEmailAndPassword(auth, email, password)
      navigate('/afiliado/dashboard')
    } catch (error) {
      let errorMessage = 'Ocorreu um erro, tente novamente.'

      if ((error as any).code === 'auth/wrong-password' || (error as any).code === 'auth/user-not-found') {
        errorMessage = 'E-mail ou senha inválidos'
      }
      toast.error(errorMessage)
    }
  }

  return (
    <Container>
      <Content maxWidth='xs'>
        <Main>
          <Box sx={{ marginBottom: 8 }}>
            <img src={logo} alt='Pix do dia' />
          </Box>
          <Typography component='h1' variant='h5'>
            Login
          </Typography>
          <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='normal'
                  required
                  fullWidth
                  label='E-mail'
                  autoComplete='email'
                  type='email'
                  error={errors.email}
                  helperText={errors.email && errors.email.message}
                />
              )}
            />
            <Controller
              name='password'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='normal'
                  required
                  fullWidth
                  label='Senha'
                  type='password'
                  autoComplete='password'
                  error={errors.password}
                  helperText={errors.password && errors.password.message}
                />
              )}
            />
            <Button type='submit' fullWidth size='large' variant='contained' sx={{ mt: 2, mb: 2 }}>
              Acessar
            </Button>
            <Grid container>
              <Grid item xs>
                <StyledLink href='/afiliado/reset-password' variant='body2'>
                  Recuperar senha
                </StyledLink>
              </Grid>
              <Grid item>
                <StyledLink href='/afiliado/signup' variant='body2'>
                  Criar conta de afiliado
                </StyledLink>
              </Grid>
            </Grid>
          </Box>
        </Main>
      </Content>
    </Container>
  )
}
