import { forwardRef, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { ArrowForward, ContentCopy, InfoOutlined } from '@mui/icons-material'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import { doc, onSnapshot, setDoc, updateDoc } from 'firebase/firestore'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Controller, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import Loader from 'components/Loader'
import { globalSettings } from 'globalSettingsReact'
import logo from 'main/assets/logo-icon.png'
import { functionsBaseURL } from 'main/constants'
import { firestore } from 'main/firebase'
import { currencyFormatter } from 'main/functions'

import { PriceSlider } from '../PriceSlider'
import {
  Actions,
  CancelButton,
  Container,
  Content,
  CopyButton,
  DoneInfo,
  DoneMessage,
  DoneNote,
  DoneNumber,
  DoneSubtitle,
  DoneTitle,
  Form,
  Header,
  HelperText,
  InfoList,
  Item,
  Logo,
  PixData,
  PixImage,
  StyledButton,
  Subtitle,
  Texts,
  Title
} from './styles'

interface IPixInfo {
  imagemQrcode: string
  qrcode: string
  txid?: string
  saleData: ISaleData
}

interface ISaleData {
  ticketPrice: number
  ticketQuantity: number
  price: number
}

const PaymentModal = forwardRef<any, any>(({ handleClose }, ref) => {
  const params = useParams()

  const [selectedTicketPrice, setSelectedTicketPrice] = useState(globalSettings.ticketPrice)
  const [saveFormData, setSaveFormData] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [step, setStep] = useState('form')
  const [paymentInfo, setPaymentInfo] = useState<any>(null)
  const [pixInfo, setPixInfo] = useState<IPixInfo>({
    qrcode: '',
    imagemQrcode: '',
    saleData: {
      ticketPrice: 0,
      ticketQuantity: 0,
      price: 0
    }
  })
  let pixInterval

  const schema = yup
    .object({
      name: yup
        .string()
        .matches(/^[a-zA-ZÀ-ÿ]+(\s[a-zA-ZÀ-ÿ]+)+$/, 'Informe nome e sobrenome')
        .max(40)
        .required('Informe um nome válido'),
      phoneNumber: yup
        .string()
        .min(11, 'Informe um número com DDD válido')
        .required('Informe um número com DDD válido'),
      email: yup.string().email('Informe um e-mail válido'),
      pixKey: yup.string().required('Campo obrigatório'),
      pixKeyType: yup.string().required('Campo obrigatório')
    })
    .required()

  // regex for validate first name and last name, in other words, two strings separated by a space

  const {
    reset,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      phoneNumber: '',
      email: '',
      pixKey: '',
      pixKeyType: 'CPF/CNPJ'
    }
  })
  const pixKeyType = watch('pixKeyType')

  useEffect(() => {
    const savedData = localStorage.getItem('user-data') && JSON.parse(localStorage.getItem('user-data') as string)
    if (savedData) {
      reset(savedData)
    }
  }, [])

  const saveDataOnLocalStorage = data => {
    if (saveFormData) {
      const stringify = JSON.stringify(data)

      localStorage.setItem('user-data', stringify)
    } else {
      localStorage.removeItem('user-data')
    }
  }

  const onSubmit = async data => {
    saveDataOnLocalStorage(data)

    const ticketQuantity = selectedTicketPrice / globalSettings.ticketPrice
    const body = { ...data, ticketQuantity }

    setIsSubmitting(true)
    const generateChargeURL = `${functionsBaseURL}/pix/generate-charge`
    const pixData = await axios.post(generateChargeURL, body)
    const paymentRef = doc(firestore, `payments`, pixData.data.txid)

    await setDoc(paymentRef, {
      ...pixData.data,
      referrerCode: params.referrerCode || null,
      additionalInfo: data
    })

    onSnapshot(paymentRef, snapshot => {
      setPaymentInfo(snapshot.data())
    })

    let totalRequests = 0

    pixInterval = setInterval(async () => {
      totalRequests++
      const checkPixURL = `${functionsBaseURL}/pix/check?txid=${pixData.data.txid}`
      const result = await axios.get(checkPixURL)
      if (totalRequests === 30 || result?.data?.status === 'CONCLUIDA') {
        await updateDoc(
          paymentRef,
          {
            ...result.data,
            additionalInfo: data
          },
          {
            merge: true
          }
        )
        clearInterval(pixInterval)
      }
    }, 10000)

    setPixInfo(pixData.data)
    setStep('payment')
    setIsSubmitting(false)
  }

  const handleBackOrClose = () => {
    clearInterval(pixInterval)
    if (step === 'form') {
      handleClose()
    } else {
      setStep('form')
    }
  }

  const handleSliderChange = (event, value) => {
    const { target } = event
    setSelectedTicketPrice(value)
  }

  return (
    <Container ref={ref}>
      <Content>
        <Header>
          <Texts>
            <Title>Concorrer ao PIX do dia</Title>
            <Subtitle>Preencha os dados abaixo para concorrer ao sorteio</Subtitle>
          </Texts>
          <Logo>
            <img src={logo} alt='' />
          </Logo>
        </Header>
        <Box sx={{ mt: 1 }}>
          {step === 'payment' ? (
            <Grid container spacing={2}>
              {paymentInfo?.status === 'CONCLUIDA' && (
                <DoneMessage>
                  <DoneTitle>Parabéns!</DoneTitle>
                  <DoneSubtitle>Você está participando do sorteio</DoneSubtitle>

                  <DoneNumber>
                    O número do seu PIX do sorteio é<b>{paymentInfo.pix[0].endToEndId}</b>
                  </DoneNumber>

                  <DoneNote>
                    E não se esqueça, você pode fazer quantos PIX quiser. <br />
                    <b>Quanto mais PIX você fizer, mais chances você tem de ganhar!</b>
                  </DoneNote>

                  <DoneInfo>
                    Você pode acompanhar o resultado do sorteio aqui no <b>www.pixdodia.com</b> ou{' '}
                    <a
                      href='https://chat.whatsapp.com/HjYTDZfaf702mUkC8BiCpp'
                      target='_blank'
                      rel='noreferrer noopener'
                    >
                      WhatsApp
                    </a>
                    . Caso você seja sorteado e tenha informado seu e-mail, te avisaremos via e-mail.
                  </DoneInfo>

                  <CancelButton onClick={handleClose} type='button' variant='outlined' color='primary'>
                    Fechar
                  </CancelButton>
                </DoneMessage>
              )}
              {paymentInfo?.status === 'ATIVA' && (
                <>
                  <Grid item xs={12} order={{ xs: 2, sm: 1 }}>
                    <HelperText>
                      <InfoOutlined />
                      Antes de fazer o pagamento, verifique se os dados abaixo estão corretos.
                    </HelperText>
                  </Grid>
                  <Grid item sm={6} xs={12} order={{ xs: 3, sm: 2 }}>
                    <InfoList>
                      <Item>
                        <span>E-mail:</span>
                        <b>{watch('email') || '--'}</b>
                      </Item>
                      <Item>
                        <span>Tipo da chave PIX</span>
                        <b>{watch('pixKeyType')}</b>
                      </Item>
                      <Item>
                        <span>Chave PIX</span>
                        <b>{watch('pixKey')}</b>
                      </Item>
                    </InfoList>
                    <CancelButton
                      onClick={handleBackOrClose}
                      type='button'
                      variant='outlined'
                      color='primary'
                      style={{ marginTop: '32px', width: '100%' }}
                    >
                      Voltar
                    </CancelButton>
                  </Grid>
                  <Grid item sm={6} xs={12} order={{ xs: 1, sm: 3 }}>
                    <PixData>
                      <span>R$ {currencyFormatter(pixInfo?.saleData?.price)}</span>

                      <PixImage>{pixInfo && <img src={pixInfo?.imagemQrcode} alt='' />}</PixImage>
                      <CopyToClipboard
                        text={pixInfo.qrcode}
                        onCopy={() => toast.success('Código copiado com sucesso!')}
                      >
                        <CopyButton>
                          Código Copia e Cola
                          <ContentCopy />
                        </CopyButton>
                      </CopyToClipboard>
                    </PixData>
                  </Grid>
                </>
              )}
            </Grid>
          ) : (
            <Form component='form' onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <PriceSlider
                    selectedTicketPrice={selectedTicketPrice}
                    unitPrice={globalSettings.ticketPrice}
                    priceMax={globalSettings.maxPurchaseAmount}
                    handleChange={handleSliderChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <HelperText>* Informe seus dados para entrarmos em contato, caso seja sorteado.</HelperText>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name='name'
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <TextField
                          {...field}
                          fullWidth
                          label='* Seu nome completo'
                          error={!!errors.name}
                          helperText={!!errors.name && errors.name.message}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name='phoneNumber'
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        {/* <TextField
                          {...field}

                        /> */}
                        <InputMask {...field} mask='99 99999 9999' disabled={false} maskChar=' '>
                          {() => (
                            <TextField
                              fullWidth
                              label='* Seu celular'
                              error={!!errors.phoneNumber}
                              helperText={!!errors.phoneNumber && errors.phoneNumber.message}
                            />
                          )}
                        </InputMask>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HelperText>* Em qual chave pix você deseja receber o prêmio, caso seja sorteado?</HelperText>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Controller
                    name='pixKeyType'
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id='pixKeyType-select-label'>* Tipo da chave</InputLabel>
                        <Select
                          fullWidth
                          labelId='pixKeyType-select-label'
                          id='pixKeyType-select'
                          label='* Tipo da chave'
                          onChange={e => {
                            field.onChange(e.target.value)
                            setValue('pixKey', '')
                          }}
                          value={field.value}
                          error={!!errors.pixKeyType}
                          defaultValue='CPF/CNPJ'
                        >
                          <MenuItem value='CPF/CNPJ'>CPF/CNPJ</MenuItem>
                          <MenuItem value='Celular'>Celular</MenuItem>
                          <MenuItem value='E-mail'>E-mail</MenuItem>
                          <MenuItem value='Aleatória'>Aleatória</MenuItem>
                        </Select>
                        {!!errors.pixKeyType && <FormHelperText>{errors.pixKeyType.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item sm={8} xs={12}>
                  <Controller
                    name='pixKey'
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <TextField
                          {...field}
                          disabled={!pixKeyType}
                          fullWidth
                          label='* Chave do PIX'
                          error={!!errors.pixKey}
                          helperText={!!errors.pixKey && errors.pixKey.message}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HelperText>Quer receber as atualizações sobre o sorteio em seu e-mail?</HelperText>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name='email'
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <TextField
                          {...field}
                          disabled={!pixKeyType}
                          fullWidth
                          label='E-mail'
                          error={!!errors.email}
                          helperText={!!errors.email && errors.email.message}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          checked={saveFormData}
                          onChange={() => setSaveFormData(!saveFormData)}
                        />
                      }
                      label='Salvar meus dados para auto-preencher no futuro.'
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <Actions>
                <CancelButton onClick={handleBackOrClose} type='button' variant='outlined' color='primary'>
                  {step === 'form' ? 'Cancelar' : 'Voltar'}
                </CancelButton>
                <StyledButton disabled={isSubmitting} type='submit' variant='contained' color='primary'>
                  {isSubmitting ? (
                    <>
                      Gerando pix
                      <Loader className='loader' color='secondary' size={24} />
                    </>
                  ) : (
                    <>
                      Continuar
                      <ArrowForward className='arrow' />
                    </>
                  )}
                </StyledButton>
              </Actions>
            </Form>
          )}
        </Box>
      </Content>
    </Container>
  )
})

export default PaymentModal
