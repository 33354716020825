import styled from 'styled-components'

export const Container = styled.div``
export const Content = styled.div`
  max-width: 740px;
  margin: auto;
  padding: 32px 0;
  text-align: justify;
  h2,
  strong {
    width: 100%;
    display: block;
    text-align: center;
  }
  ul {
    li {
      list-style-type: none;
      margin-bottom: 8px;
    }
  }
  hr {
    margin: 48px 0;
  }
  a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
