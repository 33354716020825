export const currencyFormatter = (value: number): string => {
  if (!Number(value) && value !== 0) return ''

  const amount = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'BRL'
  }).format(value)

  return `${amount}`
}
