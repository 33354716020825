import React from 'react'

import logo from 'main/assets/logo.png'

import { Footer } from './styles'

function AffiliateFooter({ admin = false }) {
  return (
    <Footer admin={admin}>
      <img src={logo} alt='Pix do dia' />
    </Footer>
  )
}

export default AffiliateFooter
