import { Box, Button, Typography } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 375px) {
    padding: 16px;
  }
`
export const Content = styled.div`
  width: 100%;
  max-width: 560px;
  min-height: 280px;
  background: #fff;
  border-radius: 16px;
  padding: 32px;
  @media (max-width: 375px) {
    padding: 16px;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const Texts = styled.div`
  width: 100%;

  @media (max-width: 480px) {
    order: 2;
    padding-top: 8px;
  }
`
export const Title = styled.div`
  font-weight: 700;
  font-size: 23px;
  color: #474747;
  @media (max-width: 480px) {
    font-size: 18px;
    text-align: center;
  }
`
export const Subtitle = styled.div`
  font-weight: normal;
  font-size: 16px;
  color: #474747;
  @media (max-width: 480px) {
    font-size: 14px;
    text-align: center;
  }
`
export const Logo = styled.div``
export const HelperText = styled(Typography)`
  font-size: 14px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  line-height: 1;
  font-weight: 600;
  svg {
    margin-right: 8px;
  }
  @media (max-width: 767px) {
    margin-bottom: 0;
    margin-top: 8px;
  }
`
export const InfoText = styled(Typography)``
export const Form = styled(Box)``
export const Actions = styled.div`
  padding-top: 32px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`
export const CancelButton = styled(Button)`
  @media (max-width: 480px) {
    font-size: 12px;
  }
`
export const StyledButton = styled(Button)`
  .arrow {
    width: 18px;
    height: 18px;
    margin-left: 8px;
  }
  .loader {
    margin-left: 8px;
  }
  @media (max-width: 480px) {
    font-size: 12px;
    .arrow {
      display: none;
    }
  }
`

export const InfoList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
`
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  span {
    color: #666;
    font-size: 14px;
  }
`

export const PixData = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.primary.main};
  padding: 16px;
  border-radius: 8px;
  span {
    padding-bottom: 8px;
    line-height: 1;
    font-size: 24px;
    color: #fff;
    font-weight: bold;
  }
`

export const PixImage = styled.div`
  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
`

export const CopyButton = styled.button`
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  color: #fff;
  font-weight: 700;
  padding-top: 8px;
  cursor: pointer;
  transition: all 0.2s linear;
  &:hover {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
  svg {
    margin-left: 8px;
  }
`
export const DoneMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px;
  @media (max-width: 480px) {
    padding: 0;
  }
`
export const DoneTitle = styled.h3`
  font-size: 32px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary.main};
  line-height: 1;
  margin: 0;
  @media (max-width: 480px) {
    font-size: 24px;
    margin-top: 16px;
  }
`
export const DoneSubtitle = styled.p`
  margin: 0;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.primary.main};
`
export const DoneNumber = styled.div`
  background: ${({ theme }) => theme.palette.primary.main};
  color: #fff;
  margin: 16px 0 32px;
  padding: 16px 24px;
  border-radius: 8px;
  line-height: 1.2;
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  b {
    margin-top: 8px;
    font-size: 16px;
  }
  @media (max-width: 480px) {
    padding: 16px;
    max-width: calc(100% - 32px);
    overflow-x: auto;
    b {
      font-size: 14px;
    }
  }
`
export const DoneNote = styled.div`
  text-align: center;
  font-size: 14px;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.palette.secondary.main};
`

export const DoneInfo = styled.div`
  text-align: center;
  font-size: 14px;
  color: #666;
  margin-bottom: 32px;
  a {
    text-decoration: none;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.primary.main};
    text-transform: uppercase;
  }
`
