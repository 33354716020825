import React from 'react'

import { Box, CircularProgress, CircularProgressProps, circularProgressClasses } from '@mui/material'

function Loader(props: CircularProgressProps) {
  return (
    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress
        variant='determinate'
        sx={{
          color: theme => theme.palette.grey[200]
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant='indeterminate'
        disableShrink
        sx={{
          color: theme => theme.palette.primary.main,
          animationDuration: '.7s',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round'
          }
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  )
}

export default Loader
