import React, { useEffect, useState } from 'react'

import { CloseOutlined, CloudDownloadOutlined, LinkOutlined } from '@mui/icons-material'
import { Button, Checkbox } from '@mui/material'
import { doc, setDoc, updateDoc } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { toast } from 'react-toastify'

import { firestore, storage } from 'main/firebase'

import {
  Container,
  Content,
  Header,
  Title,
  CloseButton,
  Form,
  SaveButton,
  StyledFormControlLabel,
  FileUrl,
  File,
  RemoveFile,
  Info,
  InfoHeader,
  InfoContent,
  InfoItem
} from './styles'

function PayoutModal({ handleClose, paymentId, affiliate, paymentData }) {
  const [paid, setPaid] = useState(paymentData?.paid || false)
  const [receipt, setReceipt] = useState<any>(paymentData?.receipt || null)

  const handleSave = async () => {
    if (paymentData) {
      await updateDoc(doc(firestore, 'affiliates', affiliate.id, 'payments', paymentId), {
        paid,
        receipt
      })
    } else {
      await setDoc(doc(firestore, 'affiliates', affiliate.id, 'payments', paymentId), {
        paid,
        receipt
      })
    }
    toast.success('Comprovante salvo com sucesso!')
    handleClose()
  }

  const handleUploadFile = async file => {
    if (!file) return
    const storageRef = ref(
      storage,
      `files/${affiliate.id}/comprovante-${paymentId}.${(file as any).name.split('.').pop()}`
    )
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      snapshot => {
        const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        console.log(prog)
      },
      error => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
          setReceipt(downloadURL)
        })
      }
    )
  }

  return (
    <Container>
      <Content>
        <Header>
          <Title>Pagamento de Afiliado</Title>
          <CloseButton type='button' onClick={handleClose}>
            <CloseOutlined />
          </CloseButton>
        </Header>
        <Info>
          <InfoHeader>Dados para pagamento</InfoHeader>
          <InfoContent>
            <InfoItem withBorder>
              <label>Tipo de chave:</label>
              <span>{affiliate.pixKeyType || 'Não informado'}</span>
            </InfoItem>
            <InfoItem>
              <label>Chave Pix:</label>
              <span>{affiliate.pixKey || 'Não informado'}</span>
            </InfoItem>
          </InfoContent>
        </Info>
        <Form>
          <StyledFormControlLabel
            paid={paid}
            control={<Checkbox defaultChecked={paid} onChange={() => setPaid(!paid)} />}
            label='Comissão paga'
          />
          <input
            id='upload-button-file'
            type='file'
            style={{ display: 'none' }}
            onChange={async e => {
              if (e.target.files) {
                // @ts-ignore
                await handleUploadFile(e.target?.files[0])
              }
            }}
          />
          <label htmlFor='upload-button-file'>
            <Button variant='outlined' component='span'>
              Anexar comprovante
            </Button>
          </label>
          {receipt && (
            <File>
              <FileUrl href={receipt}>
                <span>Baixar comprovante</span> <CloudDownloadOutlined />
              </FileUrl>
              <RemoveFile variant='outlined' color='error' onClick={() => setReceipt(null)}>
                Remover
              </RemoveFile>
            </File>
          )}

          <SaveButton variant='contained' type='button' onClick={handleSave}>
            Salvar
          </SaveButton>
        </Form>
      </Content>
    </Container>
  )
}

export default PayoutModal
