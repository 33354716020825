import { Container as MuiContainer } from '@mui/material'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import containerBackground from 'main/assets/header-background.png'

export const Container = styled.div`
  background: url(${containerBackground}) no-repeat top center;
  padding-bottom: 32px;
`
export const MainHeader = styled(MuiContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 32px;
`

export const Logo = styled(Link)`
  @media (max-width: 480px) {
    img {
      max-width: 100px;
    }
  }
`
export const Menu = styled.div`
  display: flex;
  align-items: center;
  padding-right: 0px;
  line-height: 1;
  gap: 30px;

  @media (max-width: 480px) {
    gap: 16px;
  }
`
export const ExternalLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 15px;
  transition: all 0.2s linear;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  svg {
    margin: auto;
  }
  &:hover {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`
export const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  transition: all 0.2s linear;
  &:hover {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`
export const Highlight = styled(MuiContainer)`
  width: 100%;
  text-align: center;
  padding-top: 24px;
`
export const Title = styled.div`
  font-weight: 700;
  font-size: 48px;
  line-height: 57px;
  text-transform: uppercase;
  color: #fff;
  @media (max-width: 480px) {
    font-size: 28px;
    line-height: 32px;
  }
`
export const Subtitle = styled.div`
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  margin-top: 24px;
`
export const Content = styled.div`
  position: relative;
  padding: 48px;
  border: 20px solid #32bcad;
  box-shadow: 0 20px 124px #031915, 0 4px 111px rgba(46, 39, 44, 0.2), 0 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 38px;
  background: #fff;
  max-width: 750px;
  min-height: 540px;
  width: 100%;
  margin: 64px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: calc(100% - 64px);
    box-shadow: 0 20px 124px #031915, 0 4px 48px rgba(46, 39, 44, 0.02), 0 10px 20px rgba(0, 0, 0, 0.05);
  }

  @media (max-width: 480px) {
    border-width: 16px;
    max-width: calc(100% - 32px);
    padding: 16px;
  }
`

export const CoinsIcon = styled.div`
  position: absolute;
  top: 0;
  left: -10%;
  translate: transformY(-50%);
  pointer-events: none;
  ${({ theme }) => theme.breakpoints.down('md')} {
    left: -15%;
  }
  @media (max-width: 600px) {
    display: none;
  }
`
export const AvatarIcon = styled(CoinsIcon)`
  right: -350px;
  left: auto;
  ${({ theme }) => theme.breakpoints.down('md')} {
    right: -400px;
  }
  @media (max-width: 680px) {
    right: -410px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`
