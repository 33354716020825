import { Container as MuiContainer, Button, Paper, Typography, FormControl } from '@mui/material'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled(MuiContainer)`
  padding-top: 32px;
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 32px;
  width: 100%;
  background: ${({ theme }) => theme.palette.primary.main};
`

export const HeaderContainer = styled(MuiContainer)`
  display: flex;
  align-items: center;
`

export const Logo = styled(Link)`
  height: 73px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: auto;
  }
`

export const Welcome = styled.div`
  display: flex;
  border-radius: 8px;
  flex: 1;
  margin-left: 32px;
  background: rgba(0, 0, 0, 0.2);
  padding: 16px;
  align-items: center;
`

export const WelcomeText = styled(Typography)`
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  line-height: 1;
  margin-right: auto;
`
export const SignOutButton = styled(Button)`
  border-color: #fff;
  color: #fff;
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 0 24px;
  height: 32px;
  svg {
    margin-right: 4px;
  }
  &:hover {
    border-color: ${({ theme }) => theme.palette.secondary.main};
    background: ${({ theme }) => theme.palette.secondary.main};
  }
`
export const StyledButton = styled(Button)`
  white-space: nowrap;
  margin-right: 16px;
  padding: 0 24px;
  line-height: 1;
  height: 32px;
`

export const StyledPaper = styled(Paper)`
  padding: 0 24px 24px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
`
export const PaperHeader = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 8px;
  align-items: center;
  margin-bottom: auto;
  svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
export const PaperTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  margin-left: 8px;
  color: ${({ theme }) => theme.palette.primary.main};
`

export const PaperContent = styled.div``
export const Form = styled.div`
  min-width: 70%;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const StyledFormControl = styled(FormControl)`
  margin-left: 16px;
  max-width: 120px;
`

export const Totals = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 16px 0 0;
  color: ${({ theme }) => theme.palette.primary.main};
`
export const TotalsLabel = styled.span`
  margin-right: 8px;
  font-weight: 600;
`
export const TotalsAmount = styled.span`
  font-weight: 600;
`
