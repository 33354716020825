const ticketPrice = 5
const starterAmount = 107
const commissionPercentage = 0.4
const thresholdAmount = 500
const maxTicketQuantityPurchase = 10

const maxPurchaseAmount = maxTicketQuantityPurchase * ticketPrice
const contribuitionAmountPerUser = ticketPrice * (1 - commissionPercentage)
const commissionAmount = ticketPrice * commissionPercentage
const minimumDepositToBegin = thresholdAmount - starterAmount
const minimumQuantityOfParticipants = Math.ceil(minimumDepositToBegin / contribuitionAmountPerUser)

export const globalSettings = {
  maxPurchaseAmount,
  starterAmount,
  ticketPrice,
  commissionPercentage,
  thresholdAmount,
  contribuitionAmountPerUser,
  minimumDepositToBegin,
  minimumQuantityOfParticipants,
  commissionAmount
}
