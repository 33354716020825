import React, { useEffect, useState } from 'react'

import { AttachMoney, Info, Telegram } from '@mui/icons-material'
import { Grid, Tooltip } from '@mui/material'
import { format } from 'date-fns'
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore'

import { firestore } from 'main/firebase'
import { currencyFormatter } from 'main/functions'

import {
  Container,
  CustomContainer,
  Icon,
  Item,
  Label,
  Amount,
  Date,
  Value,
  Currency,
  Title,
  TelegramButton,
  TelegramIcon,
  TelegramText,
  Id
} from './styles'

function LastRaffles() {
  const [raffles, setRaffles] = useState<any>([])

  const fetchRaffles = async () => {
    const q = query(collection(firestore, 'raffle'), orderBy('doneAt', 'desc'), limit(3))
    const querySnapshot = await getDocs(q)
    const items: any = []

    querySnapshot.forEach(doc => {
      const data = doc.data()
      const n = data.winnerPixId
      const winnerPixId = `${n.slice(0, 5)}* * * * * * * * *${n.slice(n.length - 5)}`

      items.push({
        date: data.doneAt,
        totalOfThePrize: data.totalOfThePrize,
        winnerPixId
      })
    })
    setRaffles(items)
  }

  useEffect(() => {
    fetchRaffles()
  }, [])

  return (
    <Container>
      {raffles && (
        <CustomContainer>
          <Title>Últimos sorteios</Title>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <TelegramButton href='https://t.me/pixdodiabr' target='_blank' rel='noreferrer noopener'>
                <TelegramIcon>
                  <Telegram />
                </TelegramIcon>
                <TelegramText>Acompanhe os sorteios no Telegram.</TelegramText>
              </TelegramButton>
            </Grid>
            {raffles.map(item => {
              return (
                <Grid key={`raffle-item-${item.winnerPixId}`} item sm={4} xs={12}>
                  <Item>
                    <Icon>
                      <AttachMoney />
                    </Icon>
                    <Label>Valor sorteado</Label>
                    <Amount>
                      <Currency>R$</Currency>
                      <Value>{currencyFormatter(item.totalOfThePrize)}</Value>
                    </Amount>
                    <Date>Data do sorteio: {format(item.date, 'dd/MM/yyyy')}</Date>
                    <Id>
                      <span>
                        <label>Pix sorteado</label>
                        <Tooltip
                          title='Você pode encontrar esse identificador no seu extrato bancário, dentro da área de PIX.'
                          placement='top'
                        >
                          <Info />
                        </Tooltip>
                      </span>
                      <strong>{item.winnerPixId}</strong>
                    </Id>
                  </Item>
                </Grid>
              )
            })}
          </Grid>
        </CustomContainer>
      )}
    </Container>
  )
}

export default LastRaffles
