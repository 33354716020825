import React, { useEffect } from 'react'

import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { Helmet } from 'react-helmet'
import { hotjar } from 'react-hotjar'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { ThemeProvider } from 'styled-components'

import { GlobalStyles } from 'main/theme/globalStyles'
import theme from 'main/theme/index'
import AdminDashboard from 'pages/Admin/Dashboard'
import AdminResetPassword from 'pages/Admin/ResetPassword'
import AdminSignIn from 'pages/Admin/SignIn'
import Dashboard from 'pages/Affiliate/Dashboard'
import Profile from 'pages/Affiliate/Profile'
import ResetPassword from 'pages/Affiliate/ResetPassword'
import SignIn from 'pages/Affiliate/SignIn'
import SignUp from 'pages/Affiliate/SignUp'
import Homepage from 'pages/Homepage'
import Terms from 'pages/Terms'

import 'react-toastify/dist/ReactToastify.css'

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'development') {
      hotjar.initialize(2966024, 6)
    }
  }, [])

  return (
    <div className='App'>
      {process.env.REACT_APP_ENV !== 'development' && (
        <Helmet>
          <script async src='https://www.googletagmanager.com/gtag/js?id=G-BTFGJ1X47Q' />
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
    
              gtag('config', 'G-BTFGJ1X47Q');
            `}
          </script>
        </Helmet>
      )}
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <CssBaseline />

          <Router>
            <Routes>
              <Route path='/' element={<Homepage />} />
              <Route path='/referrer/:referrerCode' element={<Homepage />} />
              <Route path='/termos' element={<Terms />} />
              <Route path='/afiliado' element={<Navigate to='/afiliado/login' />} />
              <Route path='/afiliado/login' element={<SignIn />} />
              <Route path='/afiliado/signup' element={<SignUp />} />
              <Route path='/afiliado/reset-password' element={<ResetPassword />} />
              <Route path='/afiliado/dashboard' element={<Dashboard />} />
              <Route path='/afiliado/profile' element={<Profile />} />

              <Route path='/gestor' element={<Navigate to='/gestor/login' />} />
              <Route path='/gestor/login' element={<AdminSignIn />} />
              {/* <Route path='/gestor/signup' element={<AdminSignUp />} /> */}
              <Route path='/gestor/reset-password' element={<AdminResetPassword />} />
              <Route path='/gestor/dashboard' element={<AdminDashboard />} />
              <Route
                path='*'
                element={
                  <main style={{ padding: '1rem' }}>
                    <p>Not found!</p>
                  </main>
                }
              />
            </Routes>
          </Router>
          <ToastContainer theme='colored' />
        </ThemeProvider>
      </MuiThemeProvider>
    </div>
  )
}

export default App
