import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { LogoutOutlined, AccountCircle, LinkOutlined, ContentCopy } from '@mui/icons-material'
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { doc, updateDoc, getDoc } from 'firebase/firestore'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Controller, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import AffiliateFooter from 'components/AffiliateFooter'
import Loader from 'components/Loader'
import logo from 'main/assets/logo.png'
import { auth, firestore } from 'main/firebase'
import { AffiliateContainer } from 'main/theme/globalStyles'

import {
  Container,
  SignOutButton,
  StyledButton,
  Header,
  HeaderContainer,
  Logo,
  Welcome,
  WelcomeText,
  StyledPaper,
  PaperHeader,
  PaperTitle,
  PaperContent,
  HelperText,
  AffiliateLink,
  AffiliateButton
} from './styles'

function Profile() {
  const navigate = useNavigate()
  const [user, setUser] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    onAuthStateChanged(auth, async currentUser => {
      if (currentUser) {
        const userRef = doc(firestore, 'affiliates', currentUser.uid)
        const userData = await getDoc(userRef)
        setUser({
          ...currentUser,
          ...userData.data()
        })

        setIsLoading(false)
      } else {
        navigate('/afiliado/login')
      }
    })
  }, [])

  const schema = yup
    .object({
      name: yup.string().required('Campo obrigatório'),
      email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
      pixKeyType: yup.string().required('Campo obrigatório'),
      pixKey: yup.string().required('Campo obrigatório')
    })
    .required()

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (user) {
      reset({
        name: user.name,
        email: user.email,
        pixKeyType: user.pixKeyType || 'Celular',
        pixKey: user.pixKey
      })
    }
  }, [user])

  const handleLogout = async () => {
    await signOut(auth)
    navigate('/afiliado/login')
  }

  const onSubmit = async (data: any) => {
    try {
      if (!isSubmitting) {
        setIsSubmitting(true)
        const userRef = doc(firestore, 'affiliates', user.uid)

        await updateDoc(userRef, data, {
          merge: true
        })
        toast.success('Dados atualizados com sucesso!')
      }
      setIsSubmitting(false)
    } catch {
      toast.error('Ocorreu um erro, tente novamente mais tarde!')
    }
  }

  return (
    <AffiliateContainer>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <Header>
            <HeaderContainer maxWidth='md'>
              <Logo to='/afiliado/dashboard'>
                <img src={logo} alt='Pix do Dia' />
              </Logo>
              <Welcome>
                <WelcomeText>Olá, {user.displayName}</WelcomeText>
                <StyledButton
                  // @ts-ignore
                  component={Link}
                  to='/afiliado/profile'
                  variant='contained'
                  size='small'
                >
                  Meus dados
                </StyledButton>
                <SignOutButton type='button' variant='outlined' size='small' onClick={handleLogout}>
                  <LogoutOutlined />
                  Sair
                </SignOutButton>
              </Welcome>
            </HeaderContainer>
          </Header>

          <Container maxWidth='md'>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledPaper>
                  <PaperHeader>
                    <AccountCircle />
                    <PaperTitle>Meus dados</PaperTitle>
                  </PaperHeader>
                  <PaperContent>
                    <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Controller
                            name='name'
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                margin='normal'
                                required
                                fullWidth
                                label='Nome completo'
                                autoComplete='name'
                                disabled
                                error={errors.name}
                                helperText={errors.name && errors.name.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name='email'
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                margin='normal'
                                required
                                fullWidth
                                label='E-mail'
                                autoComplete='email'
                                type='email'
                                disabled
                                error={errors.email}
                                helperText={errors.email && errors.email.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <HelperText>Informe em qual chave pix você deseja receber os pagamentos</HelperText>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Controller
                            name='pixKeyType'
                            control={control}
                            render={({ field }) => (
                              <FormControl fullWidth>
                                <InputLabel id='pixKeyType-select-label'>Tipo da chave</InputLabel>
                                <Select
                                  fullWidth
                                  labelId='pixKeyType-select-label'
                                  id='pixKeyType-select'
                                  label='Tipo da chave'
                                  onChange={e => {
                                    field.onChange(e.target.value)
                                    setValue('pixKey', '')
                                  }}
                                  value={field.value}
                                  error={!!errors.pixKeyType}
                                  defaultValue='Celular'
                                >
                                  <MenuItem value='Celular'>Celular</MenuItem>
                                  <MenuItem value='CPF/CNPJ'>CPF/CNPJ</MenuItem>
                                  <MenuItem value='E-mail'>E-mail</MenuItem>
                                  <MenuItem value='Aleatória'>Aleatória</MenuItem>
                                </Select>
                                {!!errors.pixKeyType && <FormHelperText>{errors.pixKeyType.message}</FormHelperText>}
                              </FormControl>
                            )}
                          />
                        </Grid>
                        <Grid item sm={8} xs={12}>
                          <Controller
                            name='pixKey'
                            control={control}
                            render={({ field }) => (
                              <FormControl fullWidth>
                                <TextField
                                  {...field}
                                  fullWidth
                                  label='Chave do PIX'
                                  error={!!errors.pixKey}
                                  helperText={!!errors.pixKey && errors.pixKey.message}
                                />
                              </FormControl>
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Button
                        disabled={isSubmitting}
                        type='submit'
                        fullWidth
                        size='large'
                        variant='contained'
                        sx={{ mt: 2, mb: 2 }}
                      >
                        {isSubmitting ? <Loader className='loader' color='secondary' size={24} /> : 'Salvar'}
                      </Button>
                    </Box>
                  </PaperContent>
                </StyledPaper>
                <StyledPaper>
                  <PaperHeader>
                    <LinkOutlined />
                    <PaperTitle>Meu link de afiliado</PaperTitle>
                  </PaperHeader>
                  <PaperContent>
                    <AffiliateLink>
                      <span>https://pixdodia.com/referrer/{user.referrerCode}</span>
                      <CopyToClipboard
                        text={`https://pixdodia.com/referrer/${user.referrerCode}`}
                        onCopy={() => {
                          toast.success('Link copiado com sucesso!')
                        }}
                      >
                        <AffiliateButton variant='contained' color='primary'>
                          <ContentCopy /> Clique para copiar o link
                        </AffiliateButton>
                      </CopyToClipboard>
                    </AffiliateLink>
                  </PaperContent>
                </StyledPaper>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
      <AffiliateFooter />
    </AffiliateContainer>
  )
}

export default Profile
