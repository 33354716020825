import { Slider, Typography } from '@mui/material'
import styled from 'styled-components'

const mobileBreakpoint = '600px'

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  @media (max-width: ${mobileBreakpoint}) {
    flex-direction: column-reverse;
    max-width: 90%;
    align-items: center;
    margin: 0 auto 30px;
  }

  > section {
    flex: 1;

    @media (max-width: ${mobileBreakpoint}) {
      width: 100%;
      text-align: center;
    }
  }
`

export const CustomSlider = styled(Slider)({
  color: '#52af77',
  height: 8,
  '& .MuiSlider-mark[data-index="0"], & .MuiSlider-mark[data-index="9"]': {
    opacity: 0
  },
  '& .MuiSlider-rail': {
    border: 'none',
    height: '12px'
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: '12px',
    background: '#324257'
  },
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    background: '#324257',
    boxSizing: 'content-box',
    border: '8px solid #32bcad',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
      borderColor: '#52af77'
    },
    '&:before': {
      display: 'none'
    }
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)'
    },
    '& > *': {
      transform: 'rotate(45deg)'
    }
  }
})

export const PriceContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: ${mobileBreakpoint}) {
    justify-content: center;
  }
`

export const CurrencySign = styled(Typography)`
  color: #32bcad;
  font-size: 30px;
  font-weight: bold;
  margin-right: 10px;
`

export const PriceAmount = styled(Typography)`
  color: #32bcad;
  font-size: 50px;
  font-weight: bold;
`
export const TicketsCounter = styled(Typography)`
  background: #dbdbdb;
  color: #6f6f6f;
  display: inline-block;
  padding: 2px 15px;
  border-radius: 15px;

  background: #e8fde8;
  color: #52b603;
  /* border: 1px solid #52b603; */
`

export const Image = styled.img`
  height: 150px;
  margin-left: 30px;

  @media (max-width: ${mobileBreakpoint}) {
    margin: 0;
  }
`
