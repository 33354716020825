import * as React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { sendPasswordResetEmail } from 'firebase/auth'
import { toast } from 'react-toastify'

import logo from 'main/assets/logo-black.png'
import { auth } from 'main/firebase'

import { Container, Content, Main, StyledLink } from './styles'

export default function ResetPassword() {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    sendPasswordResetEmail(auth, data.get('email') as string)
      .then(() => {
        toast.success('Enviamos um link para o seu e-mail. Utilize esse link para resetar sua senha.')
      })
      .catch(error => {
        const errorCode = error.code
        let errorMessage = 'Ocorreu um erro. Tente novamente mais tarde.'
        console.log('errorCode', errorCode)
        console.log('errorMessage', errorMessage)

        if (errorCode === 'auth/user-not-found') {
          errorMessage = 'Usuário não encontrado'
        }

        toast.error(errorMessage)
        // ..
      })
  }

  return (
    <Container>
      <Content maxWidth='xs'>
        <Main>
          <Box sx={{ marginBottom: 8 }}>
            <img src={logo} alt='Pix do dia' />
          </Box>
          <Typography component='h1' variant='h5'>
            Recuperar senha
          </Typography>
          <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label='E-mail'
              name='email'
              autoComplete='email'
              autoFocus
              helperText='Informe seu e-mail de cadastro que enviaremos um link para você resetar sua senha.'
            />
            <Button type='submit' fullWidth size='large' variant='contained' sx={{ mt: 2, mb: 2 }}>
              Enviar
            </Button>
            <Grid container>
              <Grid item xs>
                <StyledLink href='/gestor/login' variant='body2'>
                  Voltar para o login
                </StyledLink>
              </Grid>
            </Grid>
          </Box>
        </Main>
      </Content>
    </Container>
  )
}
