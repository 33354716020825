import { Typography, Container as MuiContainer } from '@mui/material'
import styled from 'styled-components'

import containerBackground from 'main/assets/last-raffle-background.png'

export const Container = styled.div`
  background: url(${containerBackground}) no-repeat center center;
  background-size: cover;
  padding: 64px 0;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 48px 0 16px;
  }
`

export const CustomContainer = styled(MuiContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 47px;
  color: ${({ theme }) => theme.palette.secondary.main};
  text-align: center;
  padding-bottom: 16px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 32px;
  }
`

export const Item = styled.div`
  background: #fff;
  box-shadow: 0 4px 26px rgba(0, 0, 0, 0.11);
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 32px 16px;
  max-width: 360px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: unset;
    margin-bottom: 48px;
  }
`
export const Icon = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  color: #fff;
  background: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -50px;
  margin-bottom: 16px;
  svg {
    width: 64px;
    height: 64px;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 64px;
    height: 64px;
    border-radius: 32px;
    margin-top: -32px;

    svg {
      width: 48px;
      height: 48px;
    }
  }
`
export const Label = styled(Typography)`
  font-weight: 400;
  font-size: 25px;
  text-align: center;
  color: ${({ theme }) => theme.palette.secondary.main};
  margin-bottom: 16px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 18px;
  }
`

export const Amount = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  position: relative;
  margin-bottom: 24px;
  padding-bottom: 16px;
  &:after {
    content: '';
    width: 90px;
    height: 3px;
    background: ${({ theme }) => theme.palette.primary.main};
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -45px;
  }
`
export const Currency = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 25px;
  line-height: 1;
  padding-right: 8px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 22px;
  }
`
export const Value = styled(Typography)`
  line-height: 1;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  font-size: 55px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 36px;
  }
`

export const Date = styled.div`
  font-weight: 500;
  font-size: 17px;
  color: #808080;
  padding-bottom: 16px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    text-align: center;
  }
`

export const Id = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #808080;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;

    label {
      padding-left: 18px;
    }

    svg {
      width: 18px;
      height: 18px;
      margin-left: 4px;
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  strong {
    color: ${({ theme }) => theme.palette.primary.main};
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    padding: 8px;
    border-radius: 4px;
    margin-top: 8px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    text-align: center;
  }
`
export const Prizes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`

export const Prize = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #363636;
  margin: 8px 0;
  line-height: 1;
  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 14px;
  }
`
export const TelegramIcon = styled.div`
  width: 48px;
  height: 48px;
  min-width: 48px;
  background: #0088cc;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  margin-right: 16px;
  transition: all 0.2s linear;
`

export const TelegramButton = styled.a`
  display: inline-flex;
  margin: 0 auto 72px;
  background: #fff;
  line-height: 1.2;
  align-items: center;
  justify-content: center;
  height: 64px;
  border-radius: 32px;
  padding: 0 8px;
  text-decoration: none;
  transition: all 0.2s linear;
  color: #0088cc;
  border: 2px solid #0088cc;
  text-align: left;
  &:hover {
    background: #0088cc;
    color: #fff;
    ${TelegramIcon} {
      background: #fff !important;
      color: #0088cc;
    }
  }

  @media (max-width: 599px) {
    margin: 0 auto 32px;
  }
`

export const TelegramText = styled.span`
  font-size: 18px;
  font-weight: 600;
  padding-right: 16px;
  @media (max-width: 767px) {
    font-size: 15px;
  }
  @media (max-width: 320px) {
    font-size: 14px;
  }
`
