import React from 'react'

import { Instagram, Telegram } from '@mui/icons-material'

import avatar from 'main/assets/avatar.png'
import coins from 'main/assets/left-coins.png'
import logo from 'main/assets/logo.png'

import RaffleBox from '../RaffleBox'
import {
  Container,
  Content,
  MainHeader,
  StyledLink,
  ExternalLink,
  Logo,
  Menu,
  Highlight,
  Subtitle,
  Title,
  CoinsIcon,
  AvatarIcon
} from './styles'

function Header({ showHighlight = true }) {
  return (
    <Container>
      <MainHeader>
        <Logo to='/'>
          <img src={logo} alt='Pix do dia' />
        </Logo>
        <Menu>
          <ExternalLink href='mailto:pixdodia@proton.me'>Contato</ExternalLink>
          <StyledLink to='/termos'>Termos</StyledLink>
          <ExternalLink href='https://www.instagram.com/pixdodiabr' target='_blank'>
            <Instagram />
          </ExternalLink>
          {/* <ExternalLink href='https://t.me/pixdodiabr' target='_blank'> */}
          <ExternalLink href='https://t.me/pixdodiabr' target='_blank'>
            <Telegram />
          </ExternalLink>
        </Menu>
      </MainHeader>
      {showHighlight && (
        <>
          <Highlight>
            <Title>
              Concorra <br />
              sem sair de casa!
            </Title>
            <Subtitle>Sorteios diários para você</Subtitle>
          </Highlight>
          <Content>
            <CoinsIcon>
              <img src={coins} alt='moedas' />
            </CoinsIcon>
            <RaffleBox />
            <AvatarIcon>
              <img src={avatar} alt='avatar' />
            </AvatarIcon>
          </Content>
        </>
      )}
    </Container>
  )
}

export default Header
