import React, { useEffect } from 'react'

import Header from '../Homepage/components/Header'
import { Container, Content } from './styles'

function Terms() {
  useEffect(() => {
    document?.getElementById('root')?.scrollIntoView()
  }, [])

  return (
    <Container>
      <Header showHighlight={false} />
      <Content>
        <h2>REGULAMENTO</h2>
        <strong>Veja abaixo todas as regras e termos</strong>
        <h4>RESPONSABILIDADES</h4>
        <ul>
          <li>1. O site PIX DO DIA faz o intermédio de pagamentos dos números reservados.</li>
          <li>
            2. Todo o gerenciamento das ações é de responsabilidade única e exclusiva da PIX DO DIA. Sendo assim, a PIX
            DO DIA se responsabiliza pela criação de ações, garantia dos prêmios, entrega dos prêmios e pagamentos
            feitos às ações.
          </li>
          <li>
            3. PIX DO DIA irá remover sem aviso prévio qualquer conteúdo ou banir a conta de qualquer participante caso
            estes infrinjam qualquer regra descrita abaixo.
          </li>
        </ul>
        <h4>CADASTRO/USO DO SITE</h4>
        <ul>
          <li>4. Apenas pessoas físicas e maiores de 18 anos podem participar do site.</li>
          <li>
            5. O usuário concede o direito prévio da divulgação do seu e-mail para a PIX DO DIA, que poderá usar esses
            dados, posteriormente, para a divulgação do mesmo, caso seja ele o vencedor.
          </li>
          <li>
            5.1 O usuário deverá preencher atentamente os dados solicitados - E-mail - O tipo da chave pix - E a chave.
            O preenchimento dos dados é de inteira responsabilidade do usuário.
          </li>
          <li>
            5.2 Assim que preenchidos os dados exigidos e obrigatórios do item 5.1, o usuário deverá conferir
            atentamente os dados preenchidos e realizar o pagamento via QR CODE no valor indicado acima da imagem do
            código.
          </li>
          <li>
            6. O usuário concede o direito de enviarmos publicidade de novos sorteios/rifas, podendo este, a qualquer
            momento, remover a autorização no rodapé de nossa página inicial.
          </li>
        </ul>
        <h4>SORTEIOS</h4>
        <ul>
          <li>
            7. Para concorrer ao sorteio do dia, o usuário deverá efetuar o recolhimento imediato dos valores até às 18h
            (horário de Brasília), caso o pagamento seja efetuado após o horário final, o valor levantado será computado
            para o sorteio do dia subsequente. Os sorteios serão realizados pelo sistema de gerenciamento de sorteios,
            desenvolvido pelo sítio, e ocorrerão de forma randômica.
          </li>
          <li>
            8. A compra dos pix &apos;s deverá ser realizada exclusivamente dentro do nosso site (
            <a href='https://pixdodia.com/'>pixdodia.com</a>) e é feita utilizando como base tecnologias Blockchain e
            USDT.
          </li>
          <li>
            10. No caso do usuário não receber a confirmação do pagamento, deverá de forma imediata entrar em contato
            nos canais de atendimento indicados no sítio e encaminhar comprovante de pagamento para a efetivação de sua
            participação do sorteio do dia, caso ultrapasse os limites impostos pelo item 7, dada a efetivação, este
            somente concorrerá ao próximo sorteio.
          </li>
          <li>
            10.1 O usuário terá o prazo de 48h (quarenta e oito horas) para reclamar o bilhete não efetivado, caso não
            ocorra, o bilhete será considerado vencido.
          </li>
          <li>11. Não será permitida a troca de titularidade dos bilhetes dos pix.</li>
        </ul>
        <h4>DO PRÊMIO</h4>
        <ul>
          <li>
            12. O prêmio a ser recebido pelo sorteado, será o acúmulo dos pagamentos enviados pelos usuários durante o
            dia, respeitando os limites de horário do sorteio (item 7) - Somatória do pix = Valor do sorteio.
          </li>
          <li>
            12.1 O sítio Pix do Dia reserva o direito de recolhimento da{' '}
            <b>taxa de administração de 40% (quarenta por cento)</b> da somatória dos pix&apos;s encaminhados no dia,
            acumulados até o encerramento para o sorteio.
          </li>
          <li>
            12.2 O valor a ser efetivado ao vencedor do sorteio será de:{' '}
            <b>Somatória dos Pix do dia - Taxa de Administração do sítio = Valor final para pagamento.</b>
          </li>
        </ul>
        <h4>VENCEDOR DO SORTEIO</h4>
        <ul>
          <li>
            12. Após o ganhador ser sorteado, o sistema encaminhará mensagem de texto direta e automática ao telefone
            indicado pelo usuário - O usuário receberá.
          </li>
          <li>13. O prêmio acumulado será encaminhado ao pix indicado pelo usuário.</li>
          <li>
            14. Os valores serão transferidos para a conta do sorteado até às 20h (vinte horas - horário de Brasília).
          </li>
          <li>
            15. Caso, por motivos diversos, o sistema tenha gerado duplicidade de Pix’s como vencedor do sorteio, será
            realizado novo sorteio e/ou o valor acumulado será transferido para o próximo dia.
          </li>
          <li>
            15.1 No caso da ocorrência do item 15, os valores encaminhados serão somados aos do próximo e assim
            sucessivamente, em caso de novo erro ou inconsistência do sítio.
          </li>
          <li>
            16. Em hipótese alguma o PIX DO DIA irá pagar dois prêmios do mesmo sorteio por quaisquer motivos que venham
            a ocorrer.
          </li>
        </ul>
        <hr />
        <h2 className='terms'>TERMOS DE USO</h2>
        <p>Toda e qualquer pessoa, maior de 18 anos, poderá participar dos sorteios disponíveis.</p>
        <h4>Da definição do ganhador</h4>

        <p>A definição do ganhador ficará sujeita aos termos e condições do regulamento.</p>
        <h4>Do cancelamento do Pix concorrente</h4>
        <p>
          O site poderá, sem aviso prévio, cancelar qualquer ação que viole um ou mais termos aqui dispostos. O criador
          da ação, desde que não gere prejuízos ao sorteio e ao site, poderá solicitar o cancelamento do pix concorrente
          ao sorteio, mediante encaminhamento de comprovante de pagamento e confirmação de todos os dados recebidos para
          concorrer ao sorteio. A solicitação deverá ser feita de maneira formal e diretamente aos canais de atendimento
          indicados pelo site.
        </p>
        <h4>Do reembolso</h4>
        <p>
          O reembolso de pix ocorrerá exclusivamente em casos de cancelamento da ação, ou seja, o criador da ação ou
          site não se obriga a reembolsar participantes que adquirirem bilhetes concorrentes por outra plataforma ou
          site que não seja o <a href='https://pixdodia.com/'>pixdodia.com</a>.
        </p>
        <h4>Das proibições</h4>
        <p>
          É completamente vedado o uso de perfis falsos visando criar ações sem a genuína intenção de realizá-las
          seguindo os termos aqui dispostos. É completamente vedado a participação do organizador em sua própria ação. O
          site pode alterar a qualquer momento estes termos visando melhorar a qualidade do serviço oferecida. O site
          informará a todos os usuários sempre que ocorrer uma alteração nos termos de uso e o usuário deverá aceitar as
          mudanças para continuar utilizando o site
        </p>
      </Content>
    </Container>
  )
}

export default Terms
