import { Telegram } from '@mui/icons-material'
import styled from 'styled-components'

const Container = styled.a`
  background: #0088cc;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  border-radius: 100%;
  color: white;

  position: fixed;
  bottom: 15px;
  right: 15px;

  z-index: 300;

  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

  svg {
    transform: translateX(-2px);
    font-size: 30px;
  }
`

export function BubbleTelegram() {
  return (
    <Container target='_blank' href='https://t.me/pixdodiabr'>
      <Telegram />
    </Container>
  )
}
