import styled from 'styled-components'

export const Footer = styled.div<{ admin?: boolean }>`
  background: ${({ theme, admin }) => (admin ? theme.palette.secondary.light : theme.palette.primary.main)};
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  img {
    width: auto;
    max-height: 40px;
    object-fit: contain;
  }
`
