import { createTheme } from '@mui/material'

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', 'Helvetica', 'Arial', sans-serif"
  },
  palette: {
    primary: {
      main: '#32BCAD'
    },
    secondary: {
      main: '#031915',
      light: '#226a5b'
    }
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            color: 'white'
          }
        }
      ]
    }
  }
})

export default theme
