import { useEffect } from 'react'

import Image01 from 'main/assets/range-01.png'
import Image02 from 'main/assets/range-02.png'
import Image03 from 'main/assets/range-03.png'
import Image04 from 'main/assets/range-04.png'
import { currencyFormatter } from 'main/functions'

import * as Styled from './styles'

const Rules = [
  // { minValue: 5, img: Image01, label: 'Chance boa' },
  // { minValue: 10, img: Image02, label: 'Chance alta' },
  // { minValue: 15, img: Image03, label: 'Chance ótima' },
  // { minValue: 20, img: Image04, label: 'Melhor chance 🚀' }
  { minValue: 5, img: Image01, label: 'Chance boa' },
  { minValue: 10, img: Image01, label: 'Chance boa' },
  { minValue: 15, img: Image01, label: 'Chance boa' },
  { minValue: 20, img: Image02, label: 'Chance alta' },
  { minValue: 25, img: Image02, label: 'Chance alta' },
  { minValue: 30, img: Image02, label: 'Chance alta' },
  { minValue: 35, img: Image03, label: 'Chance ótima' },
  { minValue: 40, img: Image03, label: 'Chance ótima' },
  { minValue: 45, img: Image03, label: 'Chance ótima' },
  { minValue: 50, img: Image04, label: 'Melhor chance 🚀' }
]

export function PriceSlider({ unitPrice, priceMax, handleChange, selectedTicketPrice }) {
  let rule

  for (const item of Rules) {
    if (item.minValue === selectedTicketPrice) {
      rule = item
      break
    }
  }

  useEffect(() => {
    Rules.map(item => {
      const img = new Image()

      img.src = item.img
      return img
    })
  }, [])

  return (
    <Styled.Container>
      <section>
        <Styled.PriceContainer>
          <Styled.CurrencySign>R$</Styled.CurrencySign>
          <Styled.PriceAmount>{currencyFormatter(selectedTicketPrice)}</Styled.PriceAmount>
        </Styled.PriceContainer>
        <Styled.CustomSlider
          value={selectedTicketPrice}
          onChange={handleChange}
          marks
          step={unitPrice}
          min={unitPrice}
          max={priceMax}
          valueLabelDisplay='off'
          valueLabelFormat={x => `R$ ${currencyFormatter(x)}`}
          aria-label='Tickets'
        />
        <Styled.TicketsCounter>{rule.label}</Styled.TicketsCounter>
      </section>
      <Styled.Image src={rule.img} />
    </Styled.Container>
  )
}
