import styled from 'styled-components'

export const HomepageContainer = styled.div``
export const Footer = styled.footer`
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  img {
    margin: auto;
  }
`

export const LeftItems = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  small {
    padding: 0 24px;
    font-size: 12px;
    color: #444;
    text-align: justify;
    @media (max-width: 767px) {
      padding: 24px 0;
    }
  }
`
export const Logo = styled.div`
  max-width: 100px;
  img {
    width: 100px;
    height: auto;
  }
`

export const LogoCuracao = styled.a`
  max-width: 110px;

  img {
    width: 100%;
    height: auto;
  }
`
