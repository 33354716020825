import React, { useEffect, useState } from 'react'

import { CheckOutlined, CloudDownloadOutlined, LogoutOutlined, MonetizationOn } from '@mui/icons-material'
import {
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress
} from '@mui/material'
import { format, getDaysInMonth, parseISO } from 'date-fns'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { query, orderBy, collection, getDocs, limit, where, endAt, onSnapshot, doc } from 'firebase/firestore'
import { Link, useNavigate } from 'react-router-dom'

import AffiliateFooter from 'components/AffiliateFooter'
import logo from 'main/assets/logo.png'
import { auth, firestore } from 'main/firebase'
import { currencyFormatter } from 'main/functions'
import { AffiliateContainer } from 'main/theme/globalStyles'
import { File, FileUrl, PaymentInfo, PaymentLabel } from 'pages/Admin/Dashboard/styles'

import {
  Container,
  SignOutButton,
  StyledButton,
  Header,
  HeaderContainer,
  Logo,
  Welcome,
  WelcomeText,
  StyledPaper,
  PaperHeader,
  PaperTitle,
  PaperContent,
  Form,
  StyledFormControl,
  Totals,
  TotalsLabel,
  TotalsAmount
} from './styles'

function Dashboard() {
  const navigate = useNavigate()
  const initialYear = 2020
  const totalOfYears = new Date().getFullYear() - initialYear

  const [month, setMonth] = useState<number>(new Date().getMonth() + 1)
  const [year, setYear] = useState<number>(new Date().getFullYear())
  const [user, setUser] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isFetchingData, setIsFetchingData] = useState(true)
  const [data, setData] = useState<any>(null)

  const [payment, setPayment] = useState<any>(null)

  useEffect(() => {
    onAuthStateChanged(auth, async currentUser => {
      if (currentUser) {
        if (currentUser.isAnonymous) {
          await signOut(auth)
          navigate('/afiliado/login')
        } else {
          setUser(currentUser)
        }
      } else {
        navigate('/afiliado/login')
      }
      setIsLoading(false)
    })
  }, [])

  const handleLogout = async () => {
    await signOut(auth)
    navigate('/afiliado/login')
  }

  const fetchMetrics = async () => {
    setIsFetchingData(true)

    const currentMonth = (month.toString().length === 1 ? '0' : '') + month
    const currentDate = parseISO(`${year}-${currentMonth}-01`)
    const lastDayOfTheMonth = getDaysInMonth(currentDate)
    const listEarnings: any = []
    const dataRef = collection(firestore, 'affiliates', user.uid, 'metrics')

    const q = query(
      dataRef,
      where('date', '>=', currentDate),
      orderBy('date'),
      limit(lastDayOfTheMonth),
      endAt(parseISO(`${year}-${currentMonth}-${lastDayOfTheMonth}`))
    )

    const querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      querySnapshot.forEach(row => {
        listEarnings.push({
          id: row.id,
          ...row.data()
        })
      })
    }
    setData({ size: querySnapshot.size, items: listEarnings })
    setIsFetchingData(false)
    const paymentRef = collection(firestore, 'affiliates', user.uid, 'payments')
    onSnapshot(doc(paymentRef, `${year}-${currentMonth}`), paymentData => {
      if (paymentData.exists()) {
        setPayment(paymentData.data())
      } else {
        setPayment(null)
      }
    })
  }

  useEffect(() => {
    if (user) {
      fetchMetrics()
    }
  }, [user, month, year])

  return (
    <AffiliateContainer>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <Header>
            <HeaderContainer maxWidth='md'>
              <Logo to='/afiliado/dashboard'>
                <img src={logo} alt='Pix do Dia' />
              </Logo>
              <Welcome>
                <WelcomeText>Olá, {user.displayName}</WelcomeText>
                <StyledButton
                  // @ts-ignore
                  component={Link}
                  to='/afiliado/profile'
                  variant='contained'
                  size='small'
                >
                  Meus dados
                </StyledButton>
                <SignOutButton type='button' variant='outlined' size='small' onClick={handleLogout}>
                  <LogoutOutlined />
                  Sair
                </SignOutButton>
              </Welcome>
            </HeaderContainer>
          </Header>

          <Container maxWidth='md'>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledPaper>
                  <PaperHeader>
                    <MonetizationOn />
                    <PaperTitle>Total de ganhos</PaperTitle>
                    <Form>
                      <StyledFormControl fullWidth size='small'>
                        <InputLabel id='month-select-label'>Mês</InputLabel>
                        <Select
                          fullWidth
                          labelId='month-select-label'
                          id='month-select'
                          label='Mês'
                          onChange={e => setMonth(e.target.value as number)}
                          value={month}
                        >
                          <MenuItem value={1}>Janeiro</MenuItem>
                          <MenuItem value={2}>Fevereiro</MenuItem>
                          <MenuItem value={3}>Março</MenuItem>
                          <MenuItem value={4}>Abril</MenuItem>
                          <MenuItem value={5}>Maio</MenuItem>
                          <MenuItem value={6}>Junho</MenuItem>
                          <MenuItem value={7}>Julho</MenuItem>
                          <MenuItem value={8}>Agosto</MenuItem>
                          <MenuItem value={9}>Setembro</MenuItem>
                          <MenuItem value={10}>Outubro</MenuItem>
                          <MenuItem value={12}>Novembro</MenuItem>
                          <MenuItem value={12}>Dezembro</MenuItem>
                        </Select>
                      </StyledFormControl>
                      <StyledFormControl fullWidth size='small'>
                        <InputLabel id='year-select-label'>Ano</InputLabel>
                        <Select
                          fullWidth
                          labelId='year-select-label'
                          id='year-select'
                          label='Ano'
                          onChange={e => setYear(e.target.value as number)}
                          value={year}
                        >
                          {
                            // @ts-ignore
                            [...Array(totalOfYears + 1).keys()].reverse().map(n => (
                              <MenuItem value={initialYear + n}>{initialYear + n}</MenuItem>
                            ))
                          }
                        </Select>
                      </StyledFormControl>
                    </Form>
                  </PaperHeader>
                  <PaperContent>
                    <Table size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell>Data</TableCell>
                          <TableCell align='right'>Total de visitantes</TableCell>
                          <TableCell align='right'>Total de ganhos do dia</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {isFetchingData ? (
                          <TableRow>
                            <TableCell colSpan={3} align='center' sx={{ py: 4 }}>
                              <CircularProgress size={24} />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <>
                            {data?.items?.length > 0 ? (
                              data.items.map(row => {
                                return (
                                  <TableRow key={row.id}>
                                    <TableCell>{format(parseISO(row.id), 'dd/MM/yyyy')}</TableCell>
                                    <TableCell align='right'>{row.visitors}</TableCell>
                                    <TableCell align='right'>R$ {currencyFormatter(row.earnings || 0)}</TableCell>
                                  </TableRow>
                                )
                              })
                            ) : (
                              <TableRow>
                                <TableCell colSpan={3} align='center'>
                                  Nenhum resultado encontrado
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        )}
                      </TableBody>
                    </Table>
                    {data && data?.items?.length > 0 && (
                      <Totals>
                        <TotalsLabel>Total de ganhos do mês:</TotalsLabel>
                        <TotalsAmount>
                          R$ {currencyFormatter(data.items.reduce((a, i) => a + parseFloat(i.earnings) || 0, 0))}
                        </TotalsAmount>
                      </Totals>
                    )}
                    {payment && payment.paid && (
                      <PaymentInfo>
                        {payment.receipt && (
                          <File>
                            <FileUrl href={payment.receipt}>
                              <span>Baixar comprovante</span> <CloudDownloadOutlined />
                            </FileUrl>
                          </File>
                        )}
                        <PaymentLabel>
                          <CheckOutlined />
                          <span>Comissão Paga</span>
                        </PaymentLabel>
                      </PaymentInfo>
                    )}
                  </PaperContent>
                </StyledPaper>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
      <AffiliateFooter />
    </AffiliateContainer>
  )
}

export default Dashboard
