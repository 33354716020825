import { useEffect, useState } from 'react'

import { Box, CircularProgress, Container as MuiContainer } from '@mui/material'
import { differenceInDays, format, parseISO, subDays } from 'date-fns'
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth'
import { collection, doc, getDoc, getDocs, limit, query, setDoc, where } from 'firebase/firestore'
import { useParams } from 'react-router-dom'

import curacao from 'main/assets/curacao.png'
import logo from 'main/assets/logo-black.png'
import { auth, firestore } from 'main/firebase'

import { BubbleTelegram } from './components/BubbleTelegram'
import { BubbleWhatsapp } from './components/BubbleWhatsapp'
import Header from './components/Header'
import HowItWorks from './components/HowItWorks'
import LastRaffles from './components/LastRaffles'
import { Footer, HomepageContainer, LeftItems, Logo, LogoCuracao } from './styles'

function Homepage() {
  const params = useParams()

  const [initializing, setInitializing] = useState(true)

  const handleVisitorsCounter = async row => {
    const visitorsRef = doc(firestore, 'affiliates', row.id, 'metrics', format(new Date(), 'yyyy-MM-dd'))
    const currentAffiliateVisitors = await getDoc(visitorsRef)
    if (currentAffiliateVisitors.exists()) {
      await setDoc(visitorsRef, {
        ...currentAffiliateVisitors.data(),
        visitors: parseInt(currentAffiliateVisitors.data().visitors) + 1
      })
    } else {
      await setDoc(visitorsRef, {
        visitors: 1,
        earnings: 0,
        date: new Date()
      })
    }
  }

  useEffect(() => {
    const currentLastAccess = window.localStorage.getItem('lastAccess')
      ? parseISO(window.localStorage.getItem('lastAccess') as string)
      : subDays(new Date(), 2)

    const currentReferrerCode = window.localStorage.getItem('referrerCode') || null
    onAuthStateChanged(auth, async currentUser => {
      if (currentUser) {
        setInitializing(false)
      } else {
        signInAnonymously(auth).then(() => {
          setInitializing(false)
        })
      }

      if (
        params.referrerCode &&
        (currentReferrerCode !== params.referrerCode || differenceInDays(new Date(), currentLastAccess) > 0)
      ) {
        window.localStorage.setItem('referrerCode', params.referrerCode)
        const affiliateRef = collection(firestore, 'affiliates')
        const q = query(affiliateRef, where('referrerCode', '==', parseInt(params.referrerCode)), limit(1))
        const querySnapshot = await getDocs(q)
        if (!querySnapshot.empty) {
          querySnapshot.forEach(row => {
            handleVisitorsCounter(row)
          })
        }
      }
      window.localStorage.setItem('lastAccess', format(new Date(), 'yyyy-MM-dd'))
    })
  }, [])

  return initializing ? (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, minHeight: '100vh' }}>
      <CircularProgress />
    </Box>
  ) : (
    <HomepageContainer>
      {/* <BubbleWhatsapp /> */}
      <BubbleTelegram />
      <Header />
      <HowItWorks />
      <LastRaffles />
      <MuiContainer>
        <Footer>
          <LeftItems>
            <LogoCuracao href='https://www.gaming-curacao.com/' target='_blank'>
              <img src={curacao} alt='GC Gaming Curacao' />
            </LogoCuracao>
            <small>
              O pixdodia.com é operado por Star Brasilian N.V. com endereço em 9 Abraham de Veerstraat, Curaçao, empresa
              registrada sob o número 10692, licenciada e autorizada pelo Governo de Curaçao para atividades de jogo
              online, sob o número GLH-OCCHKTW0701042023. Para fins de processamento de pagamentos via grupo Paysafe,
              incluindo, entre outros, Neteller e Skrill, a entidade de processamento de transações é a Star Brasilian
              N.V.
            </small>
          </LeftItems>
          <Logo>
            <img src={logo} alt='Pix do dia' />
          </Logo>
        </Footer>
      </MuiContainer>
    </HomepageContainer>
  )
}

export default Homepage
