import { Button, FormControlLabel } from '@mui/material'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  min-height: calc(100vh - 64px);
  padding: 32px;
  @media (max-width: 375px) {
    padding: 16px;
  }
`
export const Content = styled.div`
  width: 100%;
  max-width: 480px;
  background: #fff;
  border-radius: 16px;
  overflow: hidden;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  padding: 16px 24px;
  background: ${({ theme }) => theme.palette.primary.main};
`

export const Title = styled.span`
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
`
export const CloseButton = styled.button`
  border: none;
  color: #fff;
  padding: 0;
  background: transparent;
  line-height: 1;
  cursor: pointer;
`
export const Form = styled.form`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
`

export const SaveButton = styled(Button)`
  margin-top: 32px;
`

export const StyledFormControlLabel = styled(FormControlLabel)<{ paid: boolean }>`
  color: ${({ theme, paid }) => (paid ? theme.palette.primary.main : '#999')};
  margin-bottom: 16px;
  max-width: 180px;
`
export const File = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  margin-top: 16px;
  border-radius: 4px;
  padding: 8px 8px 8px 16px;
`
export const FileUrl = styled.a`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
  span {
    margin-right: 8px;
  }
`
export const RemoveFile = styled(Button)``

export const Info = styled.div`
  margin: 24px 24px 0;
  padding: 16px 16px 0;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 4px;
`
export const InfoHeader = styled.div`
  padding-bottom: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
`
export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const InfoItem = styled.div<{ withBorder?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  ${({ withBorder }) =>
    withBorder &&
    css`
      border-bottom: 1px solid #ccc;
    `};
`
