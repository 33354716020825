import { Container as MuiContainer, Button, Paper, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled(MuiContainer)`
  padding-top: 32px;
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 32px;
  width: 100%;
  background: ${({ theme }) => theme.palette.primary.main};
`

export const HeaderContainer = styled(MuiContainer)`
  display: flex;
  align-items: center;
`

export const Logo = styled(Link)`
  height: 73px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: auto;
  }
`

export const Welcome = styled.div`
  display: flex;
  border-radius: 8px;
  flex: 1;
  margin-left: 32px;
  background: rgba(0, 0, 0, 0.2);
  padding: 16px;
  align-items: center;
`

export const WelcomeText = styled(Typography)`
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  line-height: 1;
  margin-right: auto;
`
export const SignOutButton = styled(Button)`
  border-color: #fff;
  color: #fff;
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 0 24px;
  height: 32px;
  svg {
    margin-right: 4px;
  }
  &:hover {
    border-color: ${({ theme }) => theme.palette.secondary.main};
    background: ${({ theme }) => theme.palette.secondary.main};
  }
`
export const StyledButton = styled(Button)`
  white-space: nowrap;
  margin-right: 16px;
  padding: 0 24px;
  line-height: 1;
  height: 32px;
`

export const StyledPaper = styled(Paper)`
  padding: 16px 16px 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`
export const PaperHeader = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 8px;
  align-items: center;
  margin-bottom: auto;
  svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
export const PaperTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  margin-left: 8px;
  color: ${({ theme }) => theme.palette.primary.main};
`

export const PaperContent = styled.div``
export const HelperText = styled(Typography)`
  font-size: 14px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  line-height: 1;
  font-weight: 600;
  svg {
    margin-right: 8px;
  }
  @media (max-width: 767px) {
    margin-bottom: 0;
    margin-top: 8px;
  }
`
export const PaperAmount = styled(Typography)`
  padding-top: 16px;
  font-size: 36px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.secondary.main};
`

export const RequestWithdrawButton = styled(Button)`
  border-color: #fff;
  color: #fff;
  display: flex;
  align-items: center;
  line-height: 1;
  margin-left: auto;
  svg {
    margin-right: 4px;
  }
  &:hover {
    border-color: ${({ theme }) => theme.palette.secondary.main};
    background: ${({ theme }) => theme.palette.secondary.main};
  }
`

export const AffiliateLink = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
  span {
    font-weight: 700;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`

export const AffiliateButton = styled(Button)`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  margin-left: auto;
  svg {
    margin-right: 8px;
  }
`
